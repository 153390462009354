/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EducatorFormModal .wrapper {
  position: relative;
}
.EducatorFormModal .wrapper .cloudLeft {
  position: absolute;
  top: 15rem;
  left: -12rem;
}
@media (max-width: 767px) {
  .EducatorFormModal .wrapper .cloudLeft {
    display: none;
  }
}
.EducatorFormModal .wrapper .pipeRight {
  position: absolute;
  top: 2rem;
  right: -1.1rem;
}
@media (max-width: 767px) {
  .EducatorFormModal .wrapper .pipeRight {
    display: none;
  }
}
.EducatorFormModal .wrapper .cloudMiddle {
  position: absolute;
  top: 34rem;
  left: 6rem;
}
@media (max-width: 767px) {
  .EducatorFormModal .wrapper .cloudMiddle {
    display: none;
  }
}
.EducatorFormModal .selectedPackage {
  margin-top: 10rem;
}
.EducatorFormModal .buttons {
  margin-top: 4rem;
}
.EducatorFormModal .packageName {
  font-size: 5rem;
  font-weight: 600;
  color: #745a9e;
}
.EducatorFormModal .packagePrice {
  font-size: 4rem;
  font-weight: 600;
  color: #4a82ff;
  margin-top: 2rem;
}
.EducatorFormModal .packageDetails {
  font-size: 2rem;
  font-weight: 600;
  color: #534468;
  margin-top: 1.6rem;
}
