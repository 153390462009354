/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 991px) {
  .EducatorsPage,
  .SchoolsPage {
    margin-top: -5rem;
  }
}
.EducatorsPage .AboveFold,
.SchoolsPage .AboveFold {
  overflow: hidden;
  position: relative;
  background: #dd547f;
  min-height: 63rem;
}
.EducatorsPage .AboveFold > .topRight,
.SchoolsPage .AboveFold > .topRight {
  position: absolute;
  top: 8rem;
  right: 0;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold > .topRight,
  .SchoolsPage .AboveFold > .topRight {
    top: 1rem;
    display: block;
    max-width: 100%;
    height: auto;
  }
}
.EducatorsPage .AboveFold > .bottomRight,
.SchoolsPage .AboveFold > .bottomRight {
  position: absolute;
  bottom: 19rem;
  right: 0;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold > .bottomRight,
  .SchoolsPage .AboveFold > .bottomRight {
    bottom: 0;
  }
}
.EducatorsPage .AboveFold > .bottomLeft,
.SchoolsPage .AboveFold > .bottomLeft {
  position: absolute;
  bottom: 20rem;
  left: 10rem;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold > .bottomLeft,
  .SchoolsPage .AboveFold > .bottomLeft {
    display: none;
  }
}
.EducatorsPage .AboveFold .maxWidthContentWrapper,
.SchoolsPage .AboveFold .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .maxWidthContentWrapper,
  .SchoolsPage .AboveFold .maxWidthContentWrapper {
    padding: 3rem;
  }
}
.EducatorsPage .AboveFold .maxWidthContentWrapper > .title,
.SchoolsPage .AboveFold .maxWidthContentWrapper > .title {
  color: #fff;
  font-weight: 600;
  font-size: 5rem;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 4rem;
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .maxWidthContentWrapper > .title,
  .SchoolsPage .AboveFold .maxWidthContentWrapper > .title {
    font-size: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0;
    margin-top: 15rem;
  }
}
.EducatorsPage .AboveFold .maxWidthContentWrapper > .subtitle,
.SchoolsPage .AboveFold .maxWidthContentWrapper > .subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 60rem;
  margin: 3rem auto 0;
  position: relative;
  z-index: 100;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .maxWidthContentWrapper > .subtitle,
  .SchoolsPage .AboveFold .maxWidthContentWrapper > .subtitle {
    font-size: 1.8em;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.EducatorsPage .AboveFold .maxWidthContentWrapper > .buttons,
.SchoolsPage .AboveFold .maxWidthContentWrapper > .buttons {
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 75rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;
  position: relative;
  z-index: 300;
}
.EducatorsPage .AboveFold .maxWidthContentWrapper > .buttons .watchVideo,
.SchoolsPage .AboveFold .maxWidthContentWrapper > .buttons .watchVideo {
  margin-right: 7rem;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .maxWidthContentWrapper > .buttons .watchVideo,
  .SchoolsPage .AboveFold .maxWidthContentWrapper > .buttons .watchVideo {
    margin-right: 0;
  }
}
.EducatorsPage .AboveFold .partners,
.SchoolsPage .AboveFold .partners {
  text-align: center;
  min-height: 19rem;
  background: #d1426f;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .partners,
  .SchoolsPage .AboveFold .partners {
    display: none;
  }
}
.EducatorsPage .AboveFold .partners > .title,
.SchoolsPage .AboveFold .partners > .title {
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 70rem;
  margin: 12rem auto 0;
  padding-top: 3rem;
}
.EducatorsPage .AboveFold .partners .logos,
.SchoolsPage .AboveFold .partners .logos {
  margin-left: -6rem;
}
.EducatorsPage .AboveFold .partners .logos .Image,
.SchoolsPage .AboveFold .partners .logos .Image {
  display: inline-block;
  margin-top: 2rem;
  margin-right: 8rem;
  margin-left: 8rem;
}
@media (max-width: 767px) {
  .EducatorsPage .AboveFold .partners .logos .Image,
  .SchoolsPage .AboveFold .partners .logos .Image {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
.EducatorsPage .AboveFold .partners .logos .Image .bbc,
.SchoolsPage .AboveFold .partners .logos .Image .bbc {
  margin-top: -5rem;
}
.EducatorsPage .AboveFold .partners .logos .Image .childrensUniversity,
.SchoolsPage .AboveFold .partners .logos .Image .childrensUniversity {
  margin-top: -6rem;
}
.EducatorsPage .VideoSection,
.SchoolsPage .VideoSection {
  background: url('/graphics/educators/educators-video-background.svg') no-repeat #ff486b;
  background-size: contain;
  background-position: bottom;
  min-height: 55rem;
}
@media (max-width: 991px) {
  .EducatorsPage .VideoSection,
  .SchoolsPage .VideoSection {
    background-size: contain;
  }
}
.EducatorsPage .VideoSection .maxWidthContentWrapper,
.SchoolsPage .VideoSection .maxWidthContentWrapper {
  padding: 8rem;
}
@media (max-width: 767px) {
  .EducatorsPage .VideoSection .maxWidthContentWrapper,
  .SchoolsPage .VideoSection .maxWidthContentWrapper {
    padding: 3rem;
  }
}
.EducatorsPage .VideoSection .maxWidthContentWrapper > .title,
.SchoolsPage .VideoSection .maxWidthContentWrapper > .title {
  color: #fff;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 75rem;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .EducatorsPage .VideoSection .maxWidthContentWrapper > .title,
  .SchoolsPage .VideoSection .maxWidthContentWrapper > .title {
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.EducatorsPage .VideoSection .maxWidthContentWrapper > .subtitle,
.SchoolsPage .VideoSection .maxWidthContentWrapper > .subtitle {
  color: #fff;
  font-weight: 600;
  font-size: 2.2rem;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: 50rem;
  margin: 3rem auto 0;
}
@media (max-width: 767px) {
  .EducatorsPage .VideoSection .maxWidthContentWrapper > .subtitle,
  .SchoolsPage .VideoSection .maxWidthContentWrapper > .subtitle {
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.EducatorsPage .VideoSection .maxWidthContentWrapper .buttons,
.SchoolsPage .VideoSection .maxWidthContentWrapper .buttons {
  margin-top: 6rem;
  text-align: center;
}
.EducatorsPage .whatsIncludedWrapper,
.SchoolsPage .whatsIncludedWrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #f9f6ef;
  position: relative;
}
.EducatorsPage .whatsIncludedWrapper .WhatsIncludedCarousel .carouselControls .CarouselIndicator > .carouselDot.selected,
.SchoolsPage .whatsIncludedWrapper .WhatsIncludedCarousel .carouselControls .CarouselIndicator > .carouselDot.selected {
  background: #fff;
}
.EducatorsPage .whatsIncludedWrapper > .cloudRight,
.SchoolsPage .whatsIncludedWrapper > .cloudRight {
  position: absolute;
  top: 10rem;
  right: -10rem;
}
@media (max-width: 767px) {
  .EducatorsPage .whatsIncludedWrapper > .cloudRight,
  .SchoolsPage .whatsIncludedWrapper > .cloudRight {
    display: none;
  }
}
.EducatorsPage .whatsIncludedWrapper > .cloudLeft,
.SchoolsPage .whatsIncludedWrapper > .cloudLeft {
  position: absolute;
  top: 18rem;
  left: -4rem;
}
@media (max-width: 767px) {
  .EducatorsPage .whatsIncludedWrapper > .cloudLeft,
  .SchoolsPage .whatsIncludedWrapper > .cloudLeft {
    display: none;
  }
}
.EducatorsPage .buttonWrapper,
.SchoolsPage .buttonWrapper {
  padding: 12rem;
  text-align: center;
}
@media (max-width: 767px) {
  .EducatorsPage .buttonWrapper,
  .SchoolsPage .buttonWrapper {
    padding: 6rem;
  }
}
.EducatorsPage .buttonWrapper .RoundButton,
.SchoolsPage .buttonWrapper .RoundButton {
  transform: scale(1.5);
}
.EducatorsPage .PricingPackages,
.SchoolsPage .PricingPackages {
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: white;
}
.EducatorsPage .PricingPackages .title,
.SchoolsPage .PricingPackages .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  color: #745a9e;
  padding-top: 8rem;
}
@media (max-width: 767px) {
  .EducatorsPage .PricingPackages .title,
  .SchoolsPage .PricingPackages .title {
    padding-top: 4rem;
  }
}
.EducatorsPage .PricingPackages .subtitle,
.SchoolsPage .PricingPackages .subtitle {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600;
  color: #745a9e;
  padding-top: 2rem;
  max-width: 60rem;
  margin: 0 auto;
}
.EducatorsPage .PricingPackages .educatorPackages,
.SchoolsPage .PricingPackages .educatorPackages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5rem;
}
.SchoolsPage .AboveFold {
  background: #4a82ff;
}
.SchoolsPage .AboveFold .partners {
  background: #3873f7;
}
