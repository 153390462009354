/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.HowItWorks {
  background: white;
  font-family: 'Zona Pro', 'Verdana';
  padding-bottom: 3rem;
  position: relative;
}
.HowItWorks .howItWorksButtons {
  text-align: center;
  padding-bottom: 2rem;
  margin-top: 4rem;
}
.HowItWorks .cloudLeft {
  left: -11rem;
  top: 11rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .HowItWorks .cloudLeft {
    display: none;
  }
}
.HowItWorks .cloudRight {
  right: -8rem;
  top: 24rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .HowItWorks .cloudRight {
    display: none;
  }
}
.HowItWorks .centre {
  padding-left: 4rem;
  padding-right: 4rem;
}
.HowItWorks .centre .header {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  color: #095d84;
  padding-top: 5rem;
}
@media (max-width: 767px) {
  .HowItWorks .centre .header {
    padding-top: 4rem;
  }
}
.HowItWorks .centre .columns {
  max-width: 110rem;
  margin: 4rem auto 0;
}
@media (max-width: 767px) {
  .HowItWorks .centre .columns {
    margin-top: 2rem;
  }
}
.HowItWorks .centre .columns .HowItWorksColumn {
  text-align: center;
}
@media (max-width: 767px) {
  .HowItWorks .centre .columns .HowItWorksColumn {
    margin-top: 2rem;
  }
}
.HowItWorks .centre .columns .HowItWorksColumn .Animation {
  margin: 0 auto;
  max-width: 21.5rem;
}
.HowItWorks .centre .columns .HowItWorksColumn .title {
  font-size: 2.4rem;
  font-weight: 600;
  color: #095d84;
  padding-top: 1rem;
}
.HowItWorks .centre .columns .HowItWorksColumn .explanation {
  font-size: 1.5rem;
  font-weight: 600;
  color: #402e59;
  margin: 0 auto;
  padding-top: .5rem;
  width: 70%;
}
@media (max-width: 767px) {
  .HowItWorks .centre .columns .HowItWorksColumn .explanation {
    width: 100%;
  }
}
