/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Tab--theme-header {
  cursor: pointer;
  height: auto;
  transition: all 0.15s;
  padding: 0.6rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: 0 1rem;
  flex-grow: 1;
  flex-basis: 2em;
  font-weight: 600;
  padding: 2rem;
  background: #f2eee5;
}
.Tab--theme-header.Tab--selected {
  background: #fff;
}
.Tab--theme-header.Tab--purple {
  background: #534468;
  color: #fff;
}
.Tab--theme-header.Tab--purple.Tab--selected {
  color: #534468;
  background: #f9f6ef;
}
.Tab--theme-header__title {
  font-weight: 600;
  font-size: 1.9rem;
}
.Tab--theme-header__icon {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}
.Tab--theme-header__icon ~ .Tab--theme-header__title {
  font-size: 1.2rem;
}
.Tab--theme-header__description {
  font-size: 1.3rem;
  font-weight: 600;
}
.Tab--theme-header--spaceEvenly {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}
@-moz-keyframes bounceTab {
  0% {
    transform: scale(1.1) translate(0rem 0rem);
  }
  25% {
    transform: scale(1.1) translate(0rem, -1rem);
  }
  50% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
  75% {
    transform: scale(1.1) translate(0rem, -0.3rem);
  }
  100% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
}
@-webkit-keyframes bounceTab {
  0% {
    transform: scale(1.1) translate(0rem 0rem);
  }
  25% {
    transform: scale(1.1) translate(0rem, -1rem);
  }
  50% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
  75% {
    transform: scale(1.1) translate(0rem, -0.3rem);
  }
  100% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
}
@keyframes bounceTab {
  0% {
    transform: scale(1.1) translate(0rem 0rem);
  }
  25% {
    transform: scale(1.1) translate(0rem, -1rem);
  }
  50% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
  75% {
    transform: scale(1.1) translate(0rem, -0.3rem);
  }
  100% {
    transform: scale(1.1) translate(0rem, 0rem);
  }
}
@-moz-keyframes bounceTabArray {
  0% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@-webkit-keyframes bounceTabArray {
  0% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes bounceTabArray {
  0% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0deg);
    opacity: 1;
  }
}
.Tab--theme-formal,
.Tab--theme-dark,
.Tab--theme-switch {
  display: flex;
  position: relative;
  padding: 1rem 3rem;
  font-weight: 600;
  color: #534468;
  font-size: 1.8rem;
  transition: transform 0.15s;
}
.Tab--theme-formal:not(.Tab--disabled),
.Tab--theme-dark:not(.Tab--disabled),
.Tab--theme-switch:not(.Tab--disabled) {
  cursor: pointer;
}
.Tab--theme-formal img,
.Tab--theme-dark img,
.Tab--theme-switch img {
  height: 2rem;
  padding-right: 0.5rem;
}
.Tab--theme-switch {
  margin: 0 1rem;
  font-weight: 600;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: 50%;
}
.Tab--theme-switch.Tab--selected {
  cursor: default;
  border: 0.3rem solid transparent;
  animation: bounceTab 0.3s 1 forwards;
}
.Tab--theme-switch.Tab--selected::after {
  animation: bounceTabArray 0.3s 1 forwards;
}
.Tab--theme-switch.Tab--selected,
.Tab--theme-switch:hover {
  transform: scale(1.1);
}
.Tab--theme-switch::after {
  content: '';
  position: absolute;
  width: 0;
  border-left: 0.7rem solid transparent;
  height: 0;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.7rem solid transparent;
  left: 50%;
  bottom: -1.5rem;
  margin-left: -0.35rem;
}
.Tab--theme-formal {
  border: 0.1rem solid #e6e0d0;
}
.Tab-text--centred {
  text-align: center;
  align-self: center;
}
.Tab__tagText {
  background: #ffdc4a;
  color: #2d272d;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-left: 1.5rem;
}
.Tab--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.Tab--theme-switch.Tab--blue {
  background-color: #3170ff;
  color: #fff;
}
.Tab--theme-switch.Tab--blue.Tab--selected {
  border-color: #3170ff;
  background-color: #fff;
  color: #3170ff;
}
.Tab--theme-switch.Tab--blue.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--blue.Tab--selected::after {
  border-bottom-color: #3170ff;
}
.Tab--theme-switch.Tab--orange {
  background-color: #ffb23f;
  color: #fff;
}
.Tab--theme-switch.Tab--orange.Tab--selected {
  border-color: #ffb23f;
  background-color: #fff;
  color: #ffb23f;
}
.Tab--theme-switch.Tab--orange.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--orange.Tab--selected::after {
  border-bottom-color: #ffb23f;
}
.Tab--theme-switch.Tab--green {
  background-color: #74a524;
  color: #fff;
}
.Tab--theme-switch.Tab--green.Tab--selected {
  border-color: #74a524;
  background-color: #fff;
  color: #74a524;
}
.Tab--theme-switch.Tab--green.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--green.Tab--selected::after {
  border-bottom-color: #74a524;
}
.Tab--theme-switch.Tab--yellow {
  background-color: #ffd217;
  color: #fff;
}
.Tab--theme-switch.Tab--yellow.Tab--selected {
  border-color: #ffd217;
  background-color: #fff;
  color: #ffd217;
}
.Tab--theme-switch.Tab--yellow.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--yellow.Tab--selected::after {
  border-bottom-color: #ffd217;
}
.Tab--theme-switch.Tab--red {
  background-color: #ff486b;
  color: #fff;
}
.Tab--theme-switch.Tab--red.Tab--selected {
  border-color: #ff486b;
  background-color: #fff;
  color: #ff486b;
}
.Tab--theme-switch.Tab--red.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--red.Tab--selected::after {
  border-bottom-color: #ff486b;
}
.Tab--theme-switch.Tab--purple {
  background-color: #534468;
  color: #fff;
}
.Tab--theme-switch.Tab--purple.Tab--selected {
  border-color: #534468;
  background-color: #fff;
  color: #534468;
}
.Tab--theme-switch.Tab--purple.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--purple.Tab--selected::after {
  border-bottom-color: #534468;
}
.Tab--theme-switch.Tab--grey {
  background-color: #e6e0d0;
  color: #fff;
}
.Tab--theme-switch.Tab--grey.Tab--selected {
  border-color: #e6e0d0;
  background-color: #fff;
  color: #e6e0d0;
}
.Tab--theme-switch.Tab--grey.Tab--selected:hover {
  background-color: #fff;
}
.Tab--theme-switch.Tab--grey.Tab--selected::after {
  border-bottom-color: #e6e0d0;
}
.Tab--theme-formal.Tab--blue {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--blue.Tab--selected {
  background-color: #3170ff;
  color: #fff;
}
.Tab--theme-formal.Tab--orange {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--orange.Tab--selected {
  background-color: #ffb23f;
  color: #fff;
}
.Tab--theme-formal.Tab--green {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--green.Tab--selected {
  background-color: #74a524;
  color: #fff;
}
.Tab--theme-formal.Tab--yellow {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--yellow.Tab--selected {
  background-color: #ffd217;
  color: #fff;
}
.Tab--theme-formal.Tab--red {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--red.Tab--selected {
  background-color: #ff486b;
  color: #fff;
}
.Tab--theme-formal.Tab--purple {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--purple.Tab--selected {
  background-color: #534468;
  color: #fff;
}
.Tab--theme-formal.Tab--grey {
  background-color: #f2eee5;
  color: #745a9e;
}
.Tab--theme-formal.Tab--grey.Tab--selected {
  background-color: #fff;
  color: #fff;
}
.Tab--theme-dark {
  border: 0.1rem solid #bdb2ef;
  border-radius: 0.6rem;
}
.Tab--theme-dark.Tab--blue {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--blue.Tab--selected {
  background-color: #3170ff;
  color: #fff;
  border-color: #3170ff;
}
.Tab--theme-dark.Tab--orange {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--orange.Tab--selected {
  background-color: #ffb23f;
  color: #fff;
  border-color: #ffb23f;
}
.Tab--theme-dark.Tab--green {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--green.Tab--selected {
  background-color: #74a524;
  color: #fff;
  border-color: #74a524;
}
.Tab--theme-dark.Tab--yellow {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--yellow.Tab--selected {
  background-color: #ffd217;
  color: #fff;
  border-color: #ffd217;
}
.Tab--theme-dark.Tab--red {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--red.Tab--selected {
  background-color: #ff486b;
  color: #fff;
  border-color: #ff486b;
}
.Tab--theme-dark.Tab--purple {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--purple.Tab--selected {
  background-color: #534468;
  color: #fff;
  border-color: #534468;
}
.Tab--theme-dark.Tab--grey {
  color: #bdb2ef;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 0.3rem;
}
.Tab--theme-dark.Tab--grey.Tab--selected {
  background-color: #fff;
  color: #fff;
  border-color: #fff;
}
