/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.EducatorPackage {
  display: inline-block;
  width: 20%;
  border-radius: 0.8rem;
  margin: 0 1rem 6rem;
  color: #fff;
  text-align: center;
  font-weight: 600;
  height: 56rem;
  flex: 0 0 30rem;
}
@media (max-width: 1199px) {
  .EducatorPackage {
    flex: 0 0 50%;
  }
}
@media (max-width: 991px) {
  .EducatorPackage {
    flex: 0 0 90%;
  }
}
.EducatorPackage.darkGreen {
  background: #67941f;
}
.EducatorPackage.darkGreen .features {
  background: #587f1b;
}
.EducatorPackage.darkGreen .features li {
  border-bottom: 0.1rem solid #67941f;
}
.EducatorPackage.red {
  background: #ff486b;
}
.EducatorPackage.red .features {
  background: #f3365a;
}
.EducatorPackage.red .features li {
  border-bottom: 0.1rem solid #ff486b;
}
.EducatorPackage.purple {
  background: #745a9e;
}
.EducatorPackage.purple .features {
  background: #534468;
}
.EducatorPackage.purple .features li {
  border-bottom: 0.1rem solid #745a9e;
}
.EducatorPackage.blue {
  background: #4a82ff;
}
.EducatorPackage.blue .features {
  background: #3873f7;
}
.EducatorPackage.blue .features li {
  border-bottom: 0.1rem solid #4a82ff;
}
.EducatorPackage .name {
  font-size: 2rem;
  padding-top: 4rem;
}
.EducatorPackage .price {
  font-size: 4rem;
  color: #ffdc4a;
  margin-top: 1rem;
}
.EducatorPackage .frequency {
  font-size: 1rem;
  margin-top: 1rem;
}
.EducatorPackage .features {
  margin-top: 2rem;
  font-size: 1.5rem;
  list-style-type: none;
  padding-left: 0;
  height: 26.5rem;
}
.EducatorPackage .features li {
  padding: 1.5rem 2.5rem;
  text-align: left;
}
.EducatorPackage .boltOnCreditCost {
  font-size: 1.2rem;
  margin-top: 2rem;
}
.EducatorPackagesPage__TabsWrapper {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
