/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PackageComparison {
  background: #f9f6ef;
  padding-bottom: 8rem;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .PackageComparison {
    display: none;
  }
}
.PackageComparison .cloudLeft {
  left: -11rem;
  top: 11rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .PackageComparison .cloudLeft {
    display: none;
  }
}
.PackageComparison .cloudRight {
  right: -13rem;
  top: 40rem;
  position: absolute;
}
@media (max-width: 1199px) {
  .PackageComparison .cloudRight {
    display: none;
  }
}
.PackageComparison .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  color: #745a9e;
  padding-top: 8rem;
}
@media (max-width: 767px) {
  .PackageComparison .title {
    padding-top: 4rem;
  }
}
.PackageComparison .subtitle {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 600;
  color: #745a9e;
  padding-top: 2rem;
  max-width: 60rem;
  margin: 0 auto;
}
.PackageComparison table {
  margin: 6rem auto 0;
  width: 115rem;
  color: #534468;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 2rem;
  border-collapse: collapse;
}
.PackageComparison table tr td:first-of-type {
  text-align: left;
  width: 33%;
}
.PackageComparison table td {
  text-align: center;
  height: 6rem;
  border-bottom: 0.1rem solid #f9f6ef;
  background: #fff;
  padding: 1.5rem;
  width: 5rem;
}
.PackageComparison table td div {
  display: inline-block;
  margin-left: 1rem;
}
.PackageComparison table .section,
.PackageComparison table thead {
  height: 10rem;
  background: #f9f6ef;
}
.PackageComparison table .section td,
.PackageComparison table thead td,
.PackageComparison table .section th,
.PackageComparison table thead th {
  background: #f9f6ef;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}
