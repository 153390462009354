/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.Modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(117, 91, 159, 0.61);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.Modal__overlay--show {
  opacity: 1;
  visibility: visible;
}
.Modal {
  max-width: 65rem;
  max-height: 65rem;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  border-radius: 1rem;
  z-index: 100000000;
  color: #745a9e;
  background-color: #fff;
  border: 0.5rem solid #e6e0d0;
  opacity: 1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.Modal__content {
  padding: 2rem;
}
.Modal--open {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.Modal--white {
  color: #745a9e;
  background-color: #fff;
}
.Modal--grey {
  background-color: #f9f6ef;
  border: 0.5rem solid #fff;
}
.Modal--blue {
  color: #fff;
  background-color: #4a82ff;
  border: 0.5rem solid #3873f7;
}
.Modal__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
}
.Modal__close:hover {
  transform: rotate(180deg);
}
.Modal--flex--centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Modal--overflow-hidden {
  overflow: hidden;
}
.Modal--overflow-auto {
  overflow: auto;
}
