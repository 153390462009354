/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.FeatureCard {
  display: inline-block;
  width: 25%;
  position: relative;
  cursor: pointer;
  -webkit-perspective: 1000;
}
@media (max-width: 991px) {
  .FeatureCard {
    width: 50%;
  }
}
@media (max-width: 1199px) {
  .FeatureCard {
    width: 33%;
  }
}
.FeatureCard__flipper-front__imageWrapper {
  width: 95%;
  height: 89%;
  margin-top: 4%;
  margin-left: 3%;
}
.FeatureCard__flipper--flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.FeatureCard__flipper {
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}
.FeatureCard,
.FeatureCard__flipper-back,
.FeatureCard__flipper-front {
  height: 22rem;
  width: 26rem;
}
@media (max-width: 767px) {
  .FeatureCard,
  .FeatureCard__flipper-back,
  .FeatureCard__flipper-front {
    width: 10rem;
    height: 10rem;
  }
}
.FeatureCard__flipper-front__backgroundImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
.FeatureCard__flipper-front,
.FeatureCard__flipper-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.FeatureCard__flipper-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.FeatureCard__text--bottom-left {
  letter-spacing: 0.05rem;
  position: absolute;
  left: 0.8rem;
  bottom: 1.46rem;
  transition: all 0.15s;
  margin: 0;
  width: 94.4%;
  box-sizing: border-box;
  padding: 2rem;
  background: linear-gradient(180deg, transparent 0, #000);
  text-align: left;
  border-radius: 1rem;
}
@media (max-width: 767px) {
  .FeatureCard__text--bottom-left {
    left: 3%;
    bottom: .8rem;
    width: 96%;
    padding: 2rem;
    font-size: 1rem;
  }
}
.FeatureCard__text {
  color: #fff;
  font-weight: 600;
  padding: 1rem;
}
.FeatureCard__text--center {
  font-size: 2.4rem;
  font-weight: 600;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 1rem;
}
.FeatureCard__text--explanation {
  font-size: 1.4rem;
  margin: auto;
}
@media (max-width: 767px) {
  .FeatureCard__text--explanation {
    font-size: 0.8rem;
    margin: auto;
    overflow: hidden;
    bottom: -6rem;
  }
}
