/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.TabbedSelector__tabs {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.TabbedSelector__tabs--theme-switch,
.TabbedSelector__tabs--theme-formal,
.TabbedSelector__tabs--theme-dark {
  margin: 3rem 0;
  justify-content: center;
}
@media (max-width: 991px) {
  .TabbedSelector__tabs--theme-switch,
  .TabbedSelector__tabs--theme-formal,
  .TabbedSelector__tabs--theme-dark {
    margin: 2rem 0;
  }
}
.TabbedSelector__tabs--theme-header {
  justify-content: space-between;
}
