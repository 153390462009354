/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.FeaturesList {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .FeaturesList {
    display: none;
  }
}
.FeaturesList .maxWidthContentWrapper {
  padding: 5rem 10rem;
}
@media (max-width: 1199px) {
  .FeaturesList .maxWidthContentWrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.FeaturesList .header {
  padding-bottom: 8rem;
  position: relative;
}
.FeaturesList .header .title {
  text-align: center;
  font-size: 3.2rem;
  font-weight: 600;
  color: #745a9e;
  margin-top: 2rem;
  position: relative;
  z-index: 100;
}
.FeaturesList .cards {
  text-align: center;
}
.FeaturesList .footer {
  position: relative;
  margin-top: 10rem;
  margin-bottom: 10rem;
  text-align: center;
}
.FeaturesList .footer .RoundButton {
  position: relative;
  z-index: 100;
}
.FeaturesList .topCloudImageFrame img {
  position: absolute;
  left: 0;
  top: 8rem;
}
.FeaturesList .topPipeImageFrame img {
  position: absolute;
  right: 0;
  top: 5rem;
}
@media (max-width: 767px) {
  .FeaturesList .topPipeImageFrame {
    display: none;
  }
}
.FeaturesList .bottomPipeImageFrame img {
  position: absolute;
  left: 0;
  bottom: 5rem;
}
@media (max-width: 767px) {
  .FeaturesList .bottomPipeImageFrame {
    display: none;
  }
}
